.landing__page{
    height: 100vh;
    margin-top: 60px;
    background-image: url("./../../assets/codelinesbg.png"), url("./../../assets/codelinesMore.png");
    background-repeat: no-repeat, no-repeat;
    background-position: left top, right top;
    background-size: 266px, 335px;
    background-color: #f1f1f1;
    display:flex;
    flex-wrap: wrap;
    padding: 4% 0;
   
    
}

.shape{
    background:#000820;
    width: 115%;
    height:300px;
    position:absolute;
    right: -110px;
    bottom:0px;
    border-top-left-radius: 200px;
    background-image: url("./../../assets/codelinesbg.png"), url("./../../assets/codelinesMore.png");
    background-repeat: no-repeat, no-repeat;
    background-position: left top, right bottom;
    background-size: 266px, 335px;
}

.landing__page__img{
    position: relative;
}

.circle1{
    background-color: #000820;
    width: 100px;
    height: 100px;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 20px;
    box-shadow: 0px 5px 8px rgb(179, 178, 178);
    background-image: url("./../../assets/codelinesbg.png"), url("./../../assets/codelinesMore.png");
    background-repeat: no-repeat, no-repeat;
    background-position: left top, right top;
    background-size: 266px, 235px;
}

.circle2{
    background-color: #07ce49;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    position: absolute;
    top: 100px;
    left: 120px;
    box-shadow: 0px 5px 8px rgb(179, 178, 178);
    background-image: url("./../../assets/codelinesbg.png"), url("./../../assets/codelinesMore.png");
    background-repeat: no-repeat, no-repeat;
    background-position: left top, right top;
    background-size: 266px, 235px;
}

.landing__page__img img{
    max-width: 35rem;
}



.landing__page__heading h1{
    font-size: 3rem;
    font-weight: bold;
    font-family: 'Cabin',sans-serif;
    text-align: justify;
    margin-top: 15px;
}

.landing__page__heading{
    width:50vw;
    position: relative;
}

.highlight{
    color: var(--darkGreen);
    background-color: white;
    padding: 5px;
    border-radius: 10px;
}



.allProjects__list{
    padding: 2%;
}

.searchInput{
    border: 1px solid lightgray;
    width: fit-content;
    height:fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    display: flex;
    align-items: center;
    padding: 10px;
}

.searchInput input {
    border: none;
    width: 30rem;
    height:100%;
   
}

.searchInput button{
    border-radius: 0px;
}

.searchInput input:focus{
    outline: none;
}


.projectsAreaWrapper{
    display: flex;
    flex-wrap: wrap;
    margin-top: 2%;
    border-top: 1px solid lightgray;
}

.projectsFilter{
    width: 24%;
    border-right: 1px solid lightgray;
    padding: 0px;
    box-sizing: border-box;
}

.projectArea{
    width: 70%;
    box-sizing: border-box;
    padding: 15px;
    display:flex;
    flex-wrap: wrap;
}

.projectCard{
    width: 16rem;
    text-align: justify;
    border: 1px solid lightgray;
    margin: 15px;
    height: 22rem;
    position: relative;
}

.projectLink{
    color: #000820;
    font-weight: bold;
    font-size: 1rem;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 15px;
    right: 15px;
}

.projectLink:hover{
    color: #07ce49;
    text-decoration: underline;
}

.searchIcon{
    font-size: 22px;
    margin-right: 7px;
}

.featuredProjectsWrapper{
    margin-top: 1%;
    margin-bottom: 1%;
    padding: 2%;
   
}

.featuredProjectsWrapper h2{
    font-weight: bold;
    text-align: left;
}


.featuredProjects{
    display: flex;
    flex-wrap: wrap;
}
