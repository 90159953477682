.adminCourse{
    display: flex;
    flex-direction: column;
    
}

.adminCourse h1{
    font-weight: bold;
}

.adminCourse .breadCrumb{
    margin-bottom: 2%;
}

.adminCourse .editCourse .uploadDefaultImg{
    width: 10rem;

}
.editCourse{
    text-align: left;
}

.adminCourse .btn{
    background-color: var(--green);
    border: none;
    border-radius: 0px;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    padding: 2px 30px;
}

.adminCourse .btn:hover{
    background-color: var(--darkGreen);
}

.mt-20px{
    margin-top: 20px;
}

.adminCourse .enrolled__students{
    margin-top: 3%;

}

.adminCourse .enrolled__students label{
    font-weight: bold;
}

.adminCourse .enrolled__students .badge{
    background-color: var(--green);
    padding: 3px 10px;
    border-radius: 20px;
    font-weight: bold;
    margin: 5px;
    color: white;
    box-shadow: 0px 2px 3px lightgray;
}




.adminCourse .form-control{
    margin-top: 2%;
    margin-bottom: 2%;

}

.adminCourse .form-control label{
    margin-bottom: 5px;
    font-weight: bold;
    display: block;
}

.adminCourse .form-control input{
    width: 100%;
    border: 1px solid rgb(207, 206, 206);
    padding: 4px 6px;
}

.adminCourse .form-control input:focus,.adminCourse .form-control textarea {
    outline: none;
}

.adminCourse .form-control textarea{
    width: 100%;
    height: 30%;
    border: 1px solid rgb(207, 206, 206);
    padding: 4px 6px;
    resize: vertical;

}

.uploadDefaultVideo{
    width:200px;

}