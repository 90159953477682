.createCourses{
    display: flex;
    flex-direction: column;
}

.createCourses h1{
    font-size: 1.5rem;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
    text-align: left;
  

}

.createCourses .createCourseContainer{
    background-color: white;
    box-shadow: 0px 4px 6px lightgray;
    margin-top: 2%;
    padding: 3% 5%;
}

.createCourses .createCourseContainer .createForm{
    float: left;
    width: 100%;
}

.left{
    float:left;
}

.dynamic-delete-button {
    position: relative;
    top: 4px;
    margin: 0 8px;
    color: #999;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s;
  }
  .dynamic-delete-button:hover {
    color: #777;
  }
  .dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }


.createCourses .btn{
    background-color: var(--green);
    border: none;
    border-radius: 0px;
}

.createCourses .btn:hover{
    background-color: var(--darkGreen);
}

.createCourses .btnOutline{
    border-radius: 0px;
    margin-left: 2%;
}

.createCourses .btnOutline:hover{
    border-color: var(--green);
    color: var(--green);
}

.form-group{
    text-align: left;
}