.adminDashboard{
    min-height: 100vh;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected.customSideLink{
    background-color: var(--green);
}



.adminDashboard .adminNav{
    color: white;
    text-align: left;
    padding-left: 2%;
    padding-right: 2%;
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 100;
}

.adminDashboard .adminNav img{
    width: 2rem;
}

.adminDashboard .adminNav .logoArea{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 3%;
}

.adminDashboard .adminNav .logoTitles{
    display: flex;
    flex-direction: column;
    align-items: center;
}


.adminDashboard .adminNav .logoTitles small{
    margin-left: 0px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: bold;
    color: gray;
}
.adminDashboard .adminNav .logoText{
    font-size: 1.1rem;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Montserrat', sans-serif;

}


.adminDashboard .trigger{
    font-size: 1.5rem;
    cursor: pointer;
}

.adminDashboard .adminNav .navigationLinks{
    display: flex;
    justify-content: space-between;
    width: 10%;
    margin-left: auto;
    margin-right: auto;
}

.adminDashboard .adminNav .navigationLinks .navLink{
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-right: 15px;
    
}

.adminDashboard .adminNav .navigationLinks .navLink:hover{
    
    text-decoration: underline;
}

.adminDashboard .adminNav .navigationLinks .navIcon{
    color: white;
    font-size: 1.2rem;
    margin-left: 5px;
    margin-right: 5px;
}

.adminDashboard .profileItems{
    display: flex;
    justify-content: right;
    margin-right: 20%;
    

 }

 .adminDashboard .profile-avatar{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}


.adminDashboard .profileItems a{
    color: white !important;
    font-weight: bold;
}