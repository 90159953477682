.course-material-details{
    display: flex;
    flex-direction: column;
    text-align: left;
}

.course-material-details .section-header{
    margin-top: 2%;
}

.course-material-details .section-header h1{
    font-size: 1.2rem;
    font-weight: bold;
}

.courseMaterialNotebook iframe{
    min-height: 500px;
    border: 1px solid lightgray;
}

.course-material-details .courseMaterialSection{
    display: flex;
    flex-direction: column;
   

}

.course-material-details .courseMaterialSection .courseMaterialCard{
    background-color: white;
    box-shadow: 0px 4px 6px lightgray;
    padding: 10px 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.course-material-details .courseMaterialCard .courseMaterialCardHeader{
    display: flex;
    justify-content: space-between;

}

.course-material-details .courseMaterialCard .courseMaterialCardHeader h1{
    font-weight: bold;
    color: rgb(53, 52, 52);
}

.course-material-details .courseMaterialCard .description{
    text-align: left;
    color:rgb(41, 40, 40);
    margin-top: 2%;
    margin-bottom: 2%;
}

.modal-textarea{
    width: 100%;
    border: 1px solid lightgray;
    padding: 5px 10px;
    height: 10rem;
    margin-top: 20px;
    margin-bottom: 10px;
    resize: vertical;
    overflow-x: hidden;
    overflow-y: auto;
}

.course-material-details .courseMaterialCard .courseMaterialCardHeader .materialControl{
    display: flex;
    width: fit-content;
}

.course-material-details .courseMaterialCard  .controlEditIcon{
    color: rgb(63, 228, 118);
    margin-right: 10px;
    font-size: 1rem;
    cursor: pointer;
}

.course-material-details .courseMaterialCard .controlDeleteIcon{
    color: rgb(255, 5, 5);
    font-size: 1rem;
    cursor: pointer;
}

