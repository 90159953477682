.allProjects{
    text-align: left;
    display: flex;
    flex-wrap: wrap;
}


.myProject__heading{
    font-weight: bold;
    font-size: 1.3rem;
    text-align: left;
}

.projectCard{
    width: fit-content;
    background-color: white;
    box-shadow: 0px 2px 4px lightgray;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin: 10px;
}

.projectCard__img{
    margin-right: 15px;
}

.projectCard__info{
    padding: 10px;
}

.projectCard__info h1{
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 5px;
}

.projectCard__info p{
    margin-bottom: 5px;
    font-size: 13px;
    color:gray;
}

.projectCard__header{
    display: flex;
    justify-content: space-between;
}

.progress{
    width: 200px;
}