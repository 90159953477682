.footer{
    background-color: #000820;
    background-image: url("./../../assets/codelinesbg.png"), url("./../../assets/codelinesMore.png");
    background-repeat: no-repeat, no-repeat;
    background-position: left top, right bottom;
    background-size: 266px, 335px;
    padding: 3% 2%;
    padding-top: 5%;
    display: flex;
    height: 100vh;
    justify-content: space-between;
    text-align: left;
    color: white;
    z-index: 1000;
}

.footer .logoArea {
    width: 40%;
}

.footer .logoArea h2{
    font-weight: bold;
    margin-left: 3%;
    color: white;
    font-family: "Montserrat", sans-serif;
}

.footer .logoArea .logo{
    margin-bottom: 3%;
    display: flex;
    align-items: center;
    
}

.footer .para p{
    text-align: justify;
    color: rgb(100, 109, 138);
    font-family: 'Cabin',sans-serif;
}

.footer .logoArea .logo img{
    width: 3rem;
}

.footer .footerSocialArea{
    display: flex;
    justify-content: left;
    margin-top: 7%;
}

.footer .footerSocialArea .footerSocialIcon{
    margin-left: 2%;
    margin-right: 2%;
    background-color: #01113f;
    padding: 5px;
    padding-left: 15px;
    padding-top: 10px;
    padding-right: 15px;
}

.footer .footerSocialArea .socialIcon{
    font-size: 1rem;
}

.footer .footerSocialArea .footerSocialIcon:hover{
    background-color: #000820;
    transition: 180ms ease-in;
    cursor: pointer;
}

.footer .footerContentArea {
    display: flex;
    flex-direction: column;
    margin-top: 1%;
}

.footer .footerContentArea h3{
    color: white;
    font-weight: bold;
}

.footer .footerContentArea a{
     color: rgb(100, 109, 138);
}

.footer .footerContentArea a:hover{
    color: white;
    text-decoration: underline;
}

.footer .footerContentArea .footerAddress{
    color: rgb(100, 109, 138);
}

.footer .footerContentArea .contentBody{
    margin-top: 20%;
    display:flex;
    flex-direction: column;
}

.footer .footerContentArea .contentBodyAddress{
    margin-top: 5%;
    display:flex;
    flex-direction: column;
}

.copyright{
width: 100%;
background-color: #01113f;
color: rgb(0, 102, 255);
padding-top: 10px;
padding-bottom: 10px;
}

@media screen and (max-width:500px){
    .footer{
        display: flex;
        flex-wrap: wrap;
        height: fit-content;
        justify-content: space-between;
        padding: 4% 5%;
    }

    .footer .logoArea{
        width: 90%;
        align-self: center;
        margin: 1%;
    }

    .footer .footerContentArea{
       margin-top: 5%;
       margin-right: 5%;
    }

    .footer .footerContentArea .contentBody{
        margin-top: 0%;
    }

    .footer .footerContentArea .contentBodyAddress{
        margin-top: 0%;
    }
}