@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&family=Gelasio:wght@400;600;700&family=Josefin+Sans:wght@600&family=Montserrat:wght@400;800&family=Nunito&family=Open+Sans:wght@400;600;700&display=swap');
:root{
  --primaryBlue:#000820;
  --darkBlue:#061625;
  /* --primaryBlue:#05192D;
  --darkBlue:#061625; */
  --orange:#ff781d;
  --darkOrange:#d66417;
  --pink:#FF5E45;
  --darkPink:#eb5740;
  --green:#03EF62;
  --darkGreen:#01df5a;
}


.ant-progress-text:not(.myProject__wrapper .ant-progress-text,.user__dashboard__course__card .ant-progress-text){
  color: white !important;
}


body {
  margin: 0;
  font-family: 'Cabin', sans-serif,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x:hidden ;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.primaryBtn{
  background: var(--green);
  color: white;
  padding: 1% 3%;
  border-radius: 3px;
  align-items: center;
  display: flex;
  width: fit-content;
  border: none;
  justify-content: center;
  text-decoration: none;
}

.outlineBtn{
  background-color: transparent;
  color: white;
  padding: 1% 3%;
  border-radius: 3px;
  align-items: center;
  display: flex;
  width: fit-content;
  border: 2px solid white;
  justify-content: space-between;
  text-decoration: none;
  cursor: pointer;
}
.outlineBtn:hover{
  background-color: white;
  color: var(--primaryBlue);
}
.green{
  color: var(--green);
}
.primaryBtn:hover{
  background: var(--darkGreen);
  color: white;
  cursor: pointer;
}

.primaryBtn:disabled,.ant-btn-primary:disabled{
  background-color: rgb(197, 196, 196) !important;
  color: gray !important;
  cursor: not-allowed;
}

.breadCrumb{
  float: left;
}

.gray{
  color: gray;
}

.breadcrumbLink {
  color: rgb(97, 96, 96);

}

.ant-table-thead > tr > th{
  font-weight: bold !important;
}

.ant-btn-primary{
  background-color: var(--green) !important;
  border: none !important;
  border-radius: 0px !important;
  font-weight: bold !important;
}

.ant-btn-primary:hover:not(.ant-btn-primary:disabled){
  background-color: var(--darkGreen) !important;
}



.ant-modal-title{
  font-weight: bold !important;
  font-size: 1.1rem !important;
}


.ant-input:hover,.ant-input-number-input:hover,input:hover :not(.ant-select-selection-search-input),textarea:hover{
  border:1px solid var(--green) !important;
}

.ant-select{
  text-align: left;
}
.ant-select:hover{
  border-color: var(--green) !important;
  outline-color: var(--green) !important;
}

.searchArea .ant-input:hover{
  border: none !important;
}

.ant-btn:hover:not(.ant-btn-primary){
  border:1px solid var(--green) !important;
  color: var(--green) !important;
}

.ant-menu-item-selected{
  background-color: var(--darkGreen) !important;
}


.ql-toolbar{
  background-color: white !important;
}

.ql-container{
  height: 50vh !important;
  overflow: auto !important;
  background-color: white !important;

}

.ql-tooltip{
  margin-left: 140px;
}

.quil{
  background-color: white !important;
}


.d-flex{
  display:flex;
}

.justify-between{
  justify-content: space-between;
}



.t__heading{
  font-weight: bold;
  font-family: 'Cabin',sans-serif;
  font-size: 1.3rem;
}

.gray__text{
  color: gray;
}