
.learn-course{
    height: fit-content;
}

.learn-course .course-material-wrapper{
    background-color: white;
    padding: 1rem;
    box-shadow: 0px 4px 6px lightgray;
    margin-top: 80px;
    height:100%;
}

.learn-course .course-material-wrapper h1{
    font-weight: bold;
}

.progressIcon{
    font-size: 2rem;
    margin-right: 10px;
    
}

.progressIconWrapper{
    margin-right: 2rem;
    color:white;
    display: flex;
    align-items: center;
    
}

.markAsCompletedCheck{
    display: flex;
    justify-content: flex-end;
}

.markAsCompletedCheck h4{
    margin-left: 10px;
}

.course-link{
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.checkIcon{
    color: var(--green);
}

.learn-course .course-material-wrapper .description{
    text-align: left;
    color:rgb(41, 40, 40);
    margin-top: 2%;
    margin-bottom: 2%;
}


.learn-course .no-data{
    margin-top: 15%;
}

.learn-course .no-data img{
    width:50%;
  
}

.learn-course .no-data h2{
    font-weight: bold;
    color: gray;
    font-family: 'Open Sans',sans-serif;
}

 .learnDashboard{
     position: fixed;
     left:0;
     right:0;
     top: 0;
 }


 .learn-course .course-material-wrapper .ql-container{
     height: 100% !important;
 }

 .instructorTitleGroup{
     display: flex;
     align-items: center;
 }

 .quizStartScreen{
     display: flex;
     align-items: center;
 }

 .quizStartScreen img{
     width: 50%;
 }

 .quizStartScreenInfo{
     margin-left: 5%;
 }

 .quizStartScreenInfo h2{
     font-size: 1.5rem;
     font-weight: bold;
 }

 

 .quizArea__question{
     border: 1px solid lightgray;
     border-radius: 5px;
     padding: 10px;
     font-weight: bold;
     text-align: left;
 }

 .quizArea__optionsWrapper{
     padding: 10px;
     
   

 }

 .quizArea__option{
     font-size: 1.2rem;
     padding: 10px;
     box-shadow: 0px 0.233px 6px rgb(214, 213, 213);
     border-radius: 5px;
     margin-top:0.8rem;
     margin-bottom: 1.3rem;
     width: 100%;
     text-align: left;
     cursor: pointer;
     color: gray;
     transition: 0.3s;
     display: flex;
     justify-content: space-between;
     align-items: center;
 }

 .quizArea__option:hover:not(.activeOption){
     box-shadow: 0px 2px 6px rgb(204, 203, 203);
     color: black;
     font-weight: bold;
 }

 .activeOption{
    background-color: rgb(233, 252, 255);
    box-shadow: none;
    border: 2px solid rgb(0, 162, 255);
    font-weight: bold;
 }

 .rightOption{
    background-color: rgb(235, 255, 233);
    box-shadow: none;
    border: 2px solid rgb(0, 255, 115);
    font-weight: bold;
 }

 .wrongOption{
    background-color: rgb(255, 233, 233);
    box-shadow: none;
    border: 2px solid rgb(255, 0, 0);
    font-weight: bold;
 }

 .quizArea__btnArea{
     display: flex;
     justify-content: flex-end;
 }

 .quizArea__showAnswer{
     text-align: left;
     visibility: hidden;
    
 }

 .quizArea__showAnswer h5{
    font-size: 1rem;
    margin-left: 15px;
 }

 .visibleShowAnswer{
    visibility: visible;
 }

 .quizCompleted{
     text-align: center;
 }

 .quizCompleted img{
     width:15rem;
     margin:10px;
 }

 .quizCompleted h3{
     font-size: 20px;
     font-weight: bold;
 }

 .notebookRenderBody{
    display: flex;
    margin-top: 1rem;

 }






.course-material-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}



 .notebookRenderWrapper  iframe{
    min-height: 500px;
    border: 1px solid lightgray;
    
    
 }





/* resize handler styles */


.ResizeHandleOuter {
    flex: 0 0 1.5em;
    position: relative;
    outline: none;
    width: fit-content;
    --background-color: transparent;
  }
  .ResizeHandleOuter[data-resize-handle-active] {
    --background-color: rgb(236, 234, 234);
  }
  
  .ResizeHandleInner {
    position: absolute;
    top: 0.25em;
    bottom: 0.25em;
    left: 0.25em;
    right: 0.25em;
    border-radius: 0.25em;
    background-color: var(--background-color);
    transition: background-color 0.2s linear;
  }
  
  .Button {
    color: gray;
    background-color: lightgray;
    border: 1px solid lightgray;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
  }
  .Button:hover {
    background-color: lightgray;
  }
  

  
  .Icon {
    width: 1em;
    height: 1em;
    position: absolute;
    left: calc(50% - 0.5rem);
    top: calc(50% - 0.5rem);
  }



  /* assistant styles */


#assistant {
  background: white;
  border: 1px solid lightgray;
 


}

#assistant #chat_container {
  flex: 1;
  width: 100%;
  overflow-y: scroll;
  height: 60vh;
  display: flex;
  flex-direction: column;
  gap: 10px;

  -ms-overflow-style: none;
  scrollbar-width: none;
 height: 70vh;
  padding-bottom: 20px;
  scroll-behavior: smooth;
}






#chat_container .wrapper {
  padding: 15px;

 
}



#chat_container .chat {
  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
}

#chat_container .profile {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  order: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}



#chat_container .profile small{
  font-size: 15px;
}

#chat_container .profile img {
  width: 80%;
  height: 80%;
  object-fit: contain;
}

#chat_container .message {
  background-color: #e8edea;
  font-size: 17px;
  order: 1;
  width: fit-content;
  overflow-x: scroll;

  white-space: pre-wrap; 

  -ms-overflow-style: none;
  scrollbar-width: none;
  text-align: left;
  padding: 10px;
  border-radius: 5px;
}



#chat_container .ai .message{
  background-color: white !important;
  border: 1px solid lightgray;
  color: black !important;
  order: 2;
  max-width: 80%;
}

#chat_container .ai .profile{
  order: 1;
}

#chat_container .ai .chat{

  justify-content: flex-start;
}


/* hides scrollbar */
#chat_container .message::-webkit-scrollbar {
  display: none;
}

#assistant form {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  background: whitesmoke;
border-top: 1px solid lightgray;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

#assistant textarea,.assistantChat textarea {
  width: 90%;

  color: black;
  font-size: 18px;

  padding: 10px;
  background: transparent;
  border-radius: 5px;
  border: none;
  outline: none;
}

#assistant textarea:hover,.assistantChat form textarea:hover{
    border: none !important ;
    outline: none !important;
}

#assistant button {
  outline: 0;
  border: 0;
  cursor: pointer;
  background: transparent;
}

#assistant .chatbotImg{
    width: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
}
 .sendIcon{
    font-size: 2.5rem;

}

.sendIcon:hover{
    color: var(--green);
    cursor: pointer;
}

#assistant form img {
  width: 30px;
  height: 30px;
}


.assistantViewWrapper{
  margin-top: 100px;
}

.assistantViewHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.assistantViewBody{
  display: flex;
  flex-wrap: wrap;
  border: 1px solid lightgray;
  background-color: white;
  margin-top: 2%;
  min-height: 70vh;

}

.assistantPdfViewer{
  width: 50%;
  border-right: 1px solid lightgray;
}

.assistantChat__imgWrapper{
  margin-top: 18%;
}

.assistantChat__img{
  width: 150px;
}

.assistantPdfViewer .pdfinfoWrapper{
  margin-top: 30%;
}

.assistantPdfViewer .pdfinfoWrapper img{
  width: 100px;
}

.assistantChat{
  width: 50%;
  background-color: whitesmoke;
  
}

.assistant__chatMsgWrapper{
  display: flex;
  flex-direction: column;
  justify-content: space-between;

 
}

.assistantChat form {
  width: 95%;
  height: 60px !important;
}

.assistantChat form textarea{
  height: 50px !important;

}

.assistant__chatMsgList{
  background-color: white;
  width: 100%;
  overflow-y: auto !important;
  height: 60vh !important;
  
}

.assistant__chatMsgHeader{
  background: rgb(68, 67, 67);
  text-align: left;
  padding: 5px;
  color: white;
  display: flex;
  align-items: center;
}

.assistant__chatMsgHeader img{
  width: 40px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 5px;

}

.assistant__chatMsgHeader h2{
  color: white;
  font-size: 17px;
  margin: 0px;
}

.assistant__chatMsgList .noMsg{
  color: gray;
  margin-top: 20%;
}






.quixArea__header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}


