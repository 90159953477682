.editor{
    background-color: white;
    height: 100%;
}

.richEditor .primaryBtn{
    font-weight: bold;
    border-radius: 0px;
    margin-top: 1rem;
}

.richEditor .editor-edit{
    padding: 10px 20px;
    overflow-y: auto;
}

.richEditor h1{
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;

}

.richEditor h2{
    font-size: 0.8rem;
    color: gray;
}



.richEditor .editorWrapper{
    /* border: 1px solid gray; */
    box-shadow: 0px 5px 8px rgb(168, 168, 168);
    min-height: 350px;
    background-color: white;
    
    
    
}
