.mentorshipSection{
    height: 100vh;
    padding:5% 2%;
    display: flex;
    background-color: #F7F7F8;
}

.mentorshipSection .mentorshipText{
    width: 50%;
    text-align: left;
    padding-right: 2%;
    padding-left: 2%;

}

.mentorshipSection .mentorshipText h1{
    font-size: 2.5rem;
    font-weight: bold;
    font-family: 'Cabin',sans-serif;
    margin-bottom: 0px;

}

.mentorshipSection .mentorshipImage{
    width: 50%;
}

.mentorshipSection .mentorshipText p{
    width: 85%;
    font-family: 'Open Sans',sans-serif;
    color: gray;
    margin-top: 10px;
    margin-bottom: 10px;
}

.mentorshipSection .mentorshipImage img{
    width: 100%;
    margin-top: -6%;
}

@media screen and (max-width:500px){
    .mentorshipSection{
        height: fit-content;
        display: flex;
        flex-direction: column;
    }

    .mentorshipSection .mentorshipText, .mentorshipSection .mentorshipImage{
        width: 100%;
        display: block;
        margin-top: 2%;
        margin-bottom: 2%;
    }

    .mentorshipSection .mentorshipImage img{
        margin-top: 2%;
        margin-left: -20%;
    }

  
}