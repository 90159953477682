.courseCard{
    width: auto;
    height:400px;
    border-radius: 2px;
    margin-top: 2%;
    margin-bottom: 2%;
    margin-right: 1rem;
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 1rem;

}
.grayShadow{
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.16);
}

.blueShadow{
    box-shadow: 0 2px 6px 0 rgba(14, 37, 77, 0.616);
}


.courseCard .img{
    width: 100%;

}

.courseCard .courseDesc{
    margin-top: 2%;
    margin-bottom: 2%;
}

.courseCard  .cardTitle{
    font-weight: bold;
    font-family: 'Cabin',sans-serif;
    font-size: 1.6rem;
    color: white;
    text-shadow: 0px 2px 2px rgb(0, 7, 29);
    
}



.courseCard .courseDesc .cardBody{
    font-size: 0.9rem;
    color: gray;
    text-align: left;
    padding: 5px;
}

.courseCard  .primaryBtn{
    width: 40%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: block;

}


@media screen and (max-width:600px) {
        .courseCard{
            width: 90% !important;
        }
}