.addCourseMaterial .addButton{
    background-color: white;
    border: 1px dashed rgb(0, 255, 76);
    padding:25px;
    width: 100px;
    height: 90px;
    text-align: center;
    cursor: pointer;

}

.addCourseMaterial .addButton .plusIcon{
    font-size: 2rem;
    color: rgb(0, 255, 76);
    cursor: pointer;

}

.uploader .ant-upload {
    width: 100%;
}

.uploadBtnWrapper{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

}

.add__coursematerial__noType{
    text-align: center;
}

.add__coursematerial__noType img{
    width:80px;
    margin: 10px;
}

.add__coursematerial__selectMsg{
    font-weight: bold;
    color: gray;
}

.materialForm input[type="text"]{
    border:1px solid lightgray;
    padding:5px 10px;
    width: 100%;

}

.materialForm input[type="text"]:focus{
    outline: none;
} 

.richEditorModal h2{
    font-size: 1rem;
    color:gray;
    font-family: 'Cabin',sans-serif;
}

.richEditorModal .primaryBtn{
    font-weight: bold;
}

.materialForm textarea{
    width:100%;
    border: 1px solid lightgray;
    margin-top: 2%;
    overflow-y: auto;
    resize: vertical;
    padding: 10px 15px;
}




.materialForm .uploader{
    margin-top: 10px;
    margin-bottom: 10px;
}


.addCourseMaterial .addAreaWrapper{
    display: flex;
    
}

.addCourseMaterial .addArea2{
    margin-left: 2%;
}

.addCourseMaterial .addArea2 .addButton{
    border: 1px dashed rgb(97, 97, 97);

}

.courseSectionCollapse{
    margin-top: 1.5%;
}

.ant-collapse-header{
    font-weight: bold;
}

.materialBox{
    padding: 5px 20px;
    border-radius: 5px;
    border: 1px solid rgb(211, 211, 211);
    background-color: rgb(252, 252, 252);
    margin-bottom: 10px;

}

.materialBox:hover{
    background-color: whitesmoke;
}

.materialBox a:hover{
    color: rgb(0, 214, 89);
}

.materialBox a{
    color: black;
}
.addCourseMaterial .addArea2 .plusIcon{
    color: rgb(86, 86, 87);
}

.projectMaterialCard{
    width: 47%;
    padding: 0.9rem;
    background-color: white;
    box-shadow: 0px 4px 6px rgb(182, 181, 181);
    margin: 1rem;
    margin-left: 0;
    border-radius: 5px;
}

.projectMaterialCard h3{
    font-weight: bold;

}

.projectMaterialCard p{
    font-size: 0.8rem;
    color: gray;
}

.allProjectMaterial{
    display: flex;
    flex-wrap: wrap;

}

.projectMaterialCard iframe{
    border: none;
}

.project-control-area{
    display: flex;
    justify-content: flex-end;
}
.project-control-area .icon{
    font-size: 1.2rem;

}

.project-control-area .icon:hover{
    color: red;
    cursor: pointer;
}