.popularCourses{
    /* background-color: rgb(0, 86, 114); */
    background-color: var(--primaryBlue);
    background-image: url("./../../assets/codelinesbg.png"), url("./../../assets/codelinesMore.png");
    background-repeat: no-repeat, no-repeat;
    background-position: left top, right bottom;
    background-size: 266px, 335px;
    height: fit-content;
    width: 100%;
    padding:2% 4%;
    margin-top: auto;
    font-family: 'Cabin',sans-serif;
}

.popularCourses h1{
    color:white;
    margin-top: 5%;
    font-size: 2.2rem;
    text-shadow: 0px 2px 3px var(--darkBlue);
}

.popularPrograms{
    background-color: rgb(241, 241, 241);
    background-image: url("./../../assets/codelinesbg.png"), url("./../../assets/codelinesMore.png");
    background-repeat: no-repeat, no-repeat;
    background-position: left top, right bottom;
    background-size: 266px, 335px;
    height: fit-content;
    width: 100%;
    padding:2% 4%;
    margin-top: auto;
    font-family: 'Cabin',sans-serif;
}

.popularProjects{
    background-color: rgb(255, 255, 255);
    background-image: url("./../../assets/codelinesbg.png"), url("./../../assets/codelinesMore.png");
    background-repeat: no-repeat, no-repeat;
    background-position: left top, right bottom;
    background-size: 266px, 335px;
    height: fit-content;
    width: 100%;
    padding:2% 4%;
    margin-top: auto;
    font-family: 'Cabin',sans-serif;
}


.popularPrograms h1{
    color: rgb(29, 28, 28);
}

.singleCourse{
    width: 30%;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 2%;
    border-radius: 10px;
    background: white;
    padding-bottom: 2%;
    font-family: 'Cabin',sans-serif;
    

}

 .singleCourse img{
    width: 80%;
}

.singleCourse h2{
    font-weight: bold;
    margin-bottom: 0px;
}

.singleCourse .desc{
    color: gray;
    margin-top: 0px;
}

 .singleCourse .cardLink{
    margin-left: auto;
    margin-right: auto;
    display: block;
}


.popularCourses__wrapper {
    display: flex;
    flex-wrap: wrap;
}


.btnArea{
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: fit-content;
    text-align: center;
    margin-top: 3%;
    margin-bottom: 3%;
}

@media screen and (max-width:500px){
     .singleCourse{
        width: 80%;
        margin-bottom: 5%;
        margin-left: auto;
        margin-right: auto;
    }
    .popularCourses__wrapper{
        display: flex;
        flex-direction: column;
    }
    
}