.user__dashboard__home{
    text-align: left;
}

.user__dashboard__home h1{
    font-weight: bold;
    font-size: 1.5rem;
}

.user__dashboard__home .user__dashboard__searchIcon{
    font-size: 1rem;
}

.user__dashboard__home .user__dashboard__search{
    margin-bottom: 3%;
}

.user__dashboard__home .user__dashboard__search input{
    width: 50%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid black;
    padding: 10px 15px;
}

.user__dashboard__home .user__dashboard__search input:focus{
    outline: none;
}

.user__dashboard__home .user__dashboard__search input:hover{
    border: none !important;
    border-bottom: 1px solid black !important;
    outline: none !important; 
}

.user__dashboard__home .user__dashboard__course__card{
    background-color: white;
    box-shadow: 0px 3px 5px 4px lightgray;
    border-radius: 10px;
    width: 70%;
    padding: 2%;
    margin: 1%;
    margin-bottom: 2%;
    display: flex;
    align-items: flex-start;
   
}

.user__dashboard__home .user__dashboard__course__card .user__dashboard__course__footer{
    display: flex;
    justify-content: space-between;
}

.user__dashboard__details__wrapper{
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    
}


.user__dashboard__home .user__dashboard__course__card .user__dashboard__course__card__img{
    width: 35%;
    margin-right: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.user__dashboard__home .user__dashboard__course__card .user__dashboard__course__details h2{
    font-weight: bold;
}

.user__dashboard__home .user__dashboard__course__card .user__dashboard__course__card__img img{
    border-radius: 10px;
    width: 100%;
    border: 1px solid black;
}

.user__dashboard__home .user__dashboard__course__card .user__dashboard__course__join{
    background-color: var(--green);
    color: white;
    padding: 5px 15px;
    height: fit-content;
    margin: 5px;
}