.codeEditor{
    text-align: left;
    height:100vh;
}

.codingEditor__heading{
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

.codingEditor__headerWrapper{
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
}

.codingEditor__label{
    font-weight: bold;
    margin-right: 10px;
}

.codingEditor__mainEditor{
    display: flex;
    flex-wrap: wrap;
}

.compiler__output__area{
    margin-left: 2%;
    width: 45%;
}

.compiler__output{
    background-color: #272822;
    color: white;
    height: 60vh;
    width: 100%;
    margin-top: 2%;
    overflow-y: auto;
    padding: 10px 15px;
}

.compiler__output code.err{
    color: red;
}