.manageCourses .tableHeading{
    display: flex;
    justify-content: space-between;
    margin-bottom: 2%;
    align-items: center;
    flex-wrap: wrap;
}

.manageCourses .tableHeading h1{
    font-weight: bold;
    font-family: 'Cabin',sans-serif;
    font-size: 1.3rem;
}

.manageCourses .btn{
    background-color: var(--green);
    color: white;
    font-weight: bold;
    font-size: 0.8rem;
    border: none;
    padding:8px 15px;
}

.manageCourses .btn:hover{
    background-color: var(--darkGreen);
    cursor: pointer;
}

.manageCourses .searchInput{
    width: 200%;
}

.manageCourses .searchArea{
    margin-right: 20%;
}