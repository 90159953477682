.student__Details__wrapper{
    background-color: white;
    box-shadow: 0px 3px 5px 3px lightgray;
    padding: 10px 20px;
    text-align: left;
}

.student__Details__card img{
    width: 50px;
}


.student__Details__card hr{
    height: 1px !important;
    color:rgba(255, 255, 255, 0.384);
    margin-top: 1%;
}
.students__details__enrolled{
    margin-top: 2%;
}

.students__details__enrolled h2{
    font-weight: bold;
    font-size: 1.2rem;
}

.students__Details__cardHeader{
    display: flex;
    justify-content: space-between;
}

.students__Details__cardHeader .badge{
    font-size: 1.5rem;
    background-color: var(--green);
    border-radius: 25px;
    font-weight: bold;
    text-align: center;
    font-family: 'Cabin',sans-serif;
    width: 100px;
    height: fit-content;
}

.students__Details__cardHeader .badge h5{
    margin: 0;
    color: white;
    text-shadow: 0px 1px 1px gray;
}

.student__details__basicDetails h2{
    font-weight: bold;
}

.student__details__basicDetails table{
    width: 100%;
}

.student__details__basicDetails table th{
    width: 25%;
}
.student__details__basicDetails table tr td,.student__details__basicDetails table tr th{
    padding: 10px 20px;
}

.student__details__basicDetails table tr:nth-child(odd){
    background-color: rgb(243, 242, 242);
}