

.error-container{
    height: 100vh;
    width: 100vw;
    margin-left: auto;
    margin-bottom: auto;
    display: block;

}

.error-btn{
    background-color: transparent;
    border: 1px solid #006B82;
    color: #006B82;
    border-radius: 50px;
    padding: 1rem;
    width: fit-content;
    font-weight: bold;
    text-decoration: none;
}

.error-btn:hover{
    background-color: #006B82;
    border: 1px solid transparent;
    color: white;
    text-decoration: none;
}