.learningMethods{
    padding: 5% 6%;
    background-color: #F7F7F8;
    height: 100vh;
}

.learningMethods h1{
    font-family: 'Cabin',sans-serif;
    font-weight: bold;
    font-size: 2rem;
}

.learningMethods .methods{
    margin-top: 7%;
    display: flex;
    justify-content: space-between;
}

.learningMethods .methods .card{
    background-color: white;
    margin-left: 20px;
    margin-right: 20px;
    width: 25%;
    padding: 3%;
    border-radius: 10px;
    box-shadow: 0px 5px 6px rgb(221, 221, 221);
}

.learningMethods .methods .card .methodIcon{
    font-size: 4rem;
    color: var(--green);
}

.learningMethods .methods .card .methodTitle{
    margin-top: 1%;
    margin-bottom: 1%;
}

.learningMethods .methods .card .methodTitle h3{
    font-weight: bold;
    font-family: 'Cabin',sans-serif;
    font-size: 1.2rem;
}

.learningMethods .methods .card .methodDesc{
    color: gray;
    font-family: 'Open Sans',sans-serif;
}

@media screen and (max-width:500px){
    .learningMethods{
        height: fit-content;
        padding-bottom: 5%;
    }
    .learningMethods .methods{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .learningMethods .methods .card{
        width: 70%;
        margin-bottom: 5%;
        align-self: center;
    }
}