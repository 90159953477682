.allPrograms{
    padding-top: 80px;
}
.programsHeader{
    background-image:
    linear-gradient(to bottom,  rgba(0, 12, 53, 0.73),rgba(4, 44, 155, 0.52)),
    url('../../assets/learning.jpg');
    width: 100%;
    min-height: 85vh;
    border-bottom-right-radius: 50% 200px !important;
    background-size: cover;
    padding: 2rem 4rem;

}

.programsHeader h1{
    text-align: left;
    margin:2%;
    margin-left: 0;
    font-size: 3rem;
    line-height: normal;
    color: white;
    font-weight: bold;
    text-shadow: 0px 2px 4px rgb(26, 25, 25);
    font-family: 'Cabin',sans-serif;
    text-transform: capitalize;

}

.allPrograms__list{
    padding: 2%;
   
}

.allPrograms__list h2{
    font-weight:bold;
    font-family: 'Cabin',sans-serif;
    font-size: 2rem;
    text-align: left;

}
.programsList__area{
    display: flex;
    flex-wrap: wrap;
   
}

.programs__category{
    width: 20%;
    padding: 1%;
   

}

.programs__list{
    width: 80%;
}


.program__category__itemWrapper{
    text-align: left;
}

.program__category__item{
    font-size: 1rem;
    font-weight: bold;
    color: rgb(112, 112, 112);
    margin-bottom: 5px;
    padding:5px;
}

.program__category__item:hover{
   background-color: rgb(230, 228, 228);
   cursor: pointer;
   color: rgb(49, 49, 49);
}

.programsCardGroup{
    display: flex;
    flex-wrap: wrap;
}

.programCard{
    border: 1px solid lightgray;
    width:25%;
    margin: 20px;
    height: max-content;
}

.programCard__top{
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.programCard__top h3{
    color: white;
    font-weight: bold;
    text-shadow: 0px 2px 3px black;
    font-size: 1.2rem;
}
.programCard__body{
    padding: 20px;
}

.programCard__body p{
    font-size: 14px;
    color: gray;
}

.programCardBtn{
    color:var(--green);
    font-size: 0.8rem;
    font-weight: bold;
    border: 2px solid var(--green);
    padding: 5px 20px;
    border-radius: 5px;
}

.programCardBtn:hover{
    background-color:var(--green);
   color: white;
}

.activeCategory{
    background-color: rgb(230, 228, 228);
    color: rgb(49, 49, 49);
}

.programListHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.popularProgram{
    padding:2%;
}

.popularProgram h2{
    text-align: left;
    font-weight: bold;
    font-size: 1.4rem;
    
}