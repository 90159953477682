.programDetails{
    padding-top: 100px;
}
.programDetails__header{
    background: var(--darkBlue);
    color: white;
    padding:2rem;
    height: 200px;
    background-position: center;
    
}

.programDetails__body{
    padding: 3% 6%;
}

.programDetails__header h2{
    color: white;
    font-weight: bold;
    font-size: 1.5rem;
}

.programDetails__cardGroup{
    display: flex;
    flex-wrap: wrap;
    
}

.programDetails__body h2{
    font-weight: bold;
    text-align: left;
}