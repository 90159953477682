


.moduleQuiz__header{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.option{
    display: flex;
    justify-content: space-between;
    margin: 5px;
    font-weight: bold;
    padding: 2px 5px;
    align-items: center;
    margin-bottom: 10px;
    padding-left: 0;
}



.quizIcon{
    font-size: 16px;
    margin-right: 15px;
    cursor: pointer;
}



.option:hover{
    background: rgb(243, 243, 243);
}