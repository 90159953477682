.courseDetails{
    height:fit-content;
    padding-top: 70px;
    padding-bottom: 100px;
}

.detailBannerWrapper{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    height: fit-content; 
    background-color: rgb(43, 42, 42);
    color: white;
    justify-content: space-between;
    padding: 2rem;

}

.preview__cardWrapper{
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 5px;
   width: 30%;
   position: fixed;
   top: 15%;
   right: 5%;
   border: 1px solid lightgray;
}

.preview__cardBody{
    padding: 10px 20px;
}

.preview__cardBody .primaryBtn{
    width: 100%;
    padding: 1rem 2rem;
}

.preview__cardBody .priceText{
    font-size: 2rem;
 
}

.preview__cardBody .priceTextWrapper{
    text-align: left;
}

.preview__cardBody .priceTextWrapper h2{
    margin: 0px;
    margin-bottom: 5px;
}

.preview__cardWrapper .priceTextWrapper p.subTitle{
    font-weight: bold;
    color: rgb(160, 0, 0);
    display: flex;
    font-size: 0.9rem;
    align-items: center;

}

.preview__cardHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.preview__cardBody .priceTextWrapper .subIcon{
    font-size: 1.3rem;
    margin-right: 5px;
}

.courseDetails .detailBanner{
    padding: 5% 7%;
    background-image: linear-gradient(to bottom, rgba(1, 26, 49, 0.788), rgba(16, 29, 75, 0.801)), var(--img);
    width: 100%;
    min-height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  
    
}

.courseDetails .detailBanner .playIcon{
    font-size: 5rem;
}

.courseDetails .detailBanner .playIcon:hover{
    color: var(--green);
}

.courseDetails .detailBanner h3{
    color: white;
    margin-top: 10px;
}

.courseDetails .detailBanner p{
    color: white;
}

.courseDetails .course__info__card{
    text-align: left;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    color: rgb(240, 240, 240);
    width: 60%;
}

.courseDetails .course__info__card p{
    font-size: 17px;
}

.course__info__card h1{
    font-size: 2.5rem;
    text-wrap: wrap;
    color: white;
}

.enrollBtnWrapper{
    display: flex;
    margin-top: 2rem;
    align-items: center;
 
}

.courseTags{
    margin-bottom: 10px;
}





.courseDetails .detailBannerWrapper .primaryBtn{
    font-weight: bold;
    border-radius: 0px;
    margin-top: 10px;
    font-size: 1rem;
}


.courseDetails .detailWrapper{
    display: flex;
    justify-content: space-between;
}
.courseDetails .detailsCard{
    width: 60%;
    border: 1px solid lightgray;
    text-align: left;
    padding: 1% 2%;
    margin: 2%;
    border-radius: 5px;
    

}

.courseDetails .detailsCard h2{
    font-weight: bold;
    color: rgb(46, 45, 45);
}

.courseDetails .detailsCard p{
    color:rgb(94, 93, 93);
}

.courseDetails .enrollDiv{
   display: flex;
   align-items: center;

}

.courseDetails .enrollDiv img{
    width:30px;
    margin: 5px;

}

.courseDetails .enrollDiv .enrollNum{
    font-size: 1.5rem;
    font-weight: bold;
    margin:0 5px;
    color: black;
}

.courseDetails .enrollDiv .enrollText{
    font-size: 1rem;
    color: rgb(83, 83, 83);
    font-weight: bold;
}

.courseDetails .learningPoint{
    display: flex;
    margin-bottom: 8px;

}

.courseDetails .learningPoint img{
    width: 10px;
}

.courseDetails .learningPoint .point{
    margin-left: 8px;
}

.courseDetails .learningPointWrapper{
    padding:10px 20px;
    border: 1px solid lightgray;
    width: 60%;
    margin: 2%;
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 5px;
}

.courseDetails .learningPointWrapper h2{
    font-weight: bold;
    text-align: left;
}


.courseDetails .courseSectionDetailsList{
    padding:10px 20px;
    border: 1px solid lightgray;
    background-color: white;
    text-align: left;
    margin: 2%;
    border-radius: 5px;
    width: 60%;
}

.courseDetails .courseSectionDetailsList h2{
    font-weight: bold;
    margin: 0;
}

.hidden{
    visibility: hidden;
}

.courseDetails .courseSectionCollapse{
    text-align: left;
}


.courseBtnWrapper{
    width: 250px;
    
    margin-left: auto;
    margin-right: auto;
    display: flex;
   
   
}

.outlineCourseBtn{
    background:transparent;
    color: white;
    border: 2px solid white;
}

.outlineCourseBtn:hover{
    background-color: white;
    color: black;

}


.payment__card__wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

}

.paymentCard{
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    min-width: 200px;
    min-height: 200px;
}

.paymentCard:hover{
    background-color: whitesmoke;
}

.paymentCard .paymentIcon{
    font-size: 4rem;
    margin: 10px;
    color: var(--green);
}