.startProjectWrapper{
    min-height: 100vh;
    display: flex;
    margin-top: 150px;
    text-align: left;
    margin-bottom: 5rem;
}


.currentVideoArea{
    width: 70%;
    box-sizing: border-box;
    padding: 1rem;
    border: 1px solid lightgray;
    margin: 1rem;
    margin-top: 0px;
}


.allProjectVideo{
    width: 25%;
    box-sizing: border-box;
    padding: 1rem;
    position: absolute;
    right: 15px;
    top: 150px;
    height: 100vh;
    overflow-y: auto;
    background-color: white;
    border: 1px solid lightgray;
  
}

.allCommentArea{
    margin-top: 15px;
}

.allCommentArea h3{
    font-weight: bold;
}

.singleComment{
    padding: 10px;
    display: flex;
    align-items: center;
}

.singleComment__info{
    margin-left:10px;
}

.replyText{
    margin-left: 10px;
    padding: 5px;
    border-radius: 2px;
}

.replyText:hover{
    background: rgb(233, 233, 233);
    cursor: pointer;
}

.likeArea{
    margin-top:10px;
}



.allProjectVideo h3{
    font-weight: bold;
    font-size: 1rem;
}

.activeVideo{
    background: rgb(238, 237, 237);
}

.videoInfo h4{
    font-weight: bold;
    font-size: 14px;
}

.videoInfo p{
    font-size: 12px;
    color: gray;
}






.videoInfo{
    width: 70%;
    margin-left: 10px;

}

.videoImg{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.playIcon{
    font-size: 2rem;
    color: white;
}

.playIcon:hover{
    color: rgb(179, 179, 179);
    cursor: pointer;
}

.videoWrapper{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    box-sizing:border-box;
    padding: 10px;
    margin-left: 0.8rem;
    margin-bottom: 10px;
}

.videoWrapper:hover{
  background: rgb(238, 237, 237);
  cursor: pointer;
}

.currentVideoArea h2{
    font-weight: bold;
}

.currentVideoInfo{
    padding: 1%;
}

.badge{
    background-color: var(--green);
    padding: 2px 7px;
    font-weight: bold;
    color: white;
    width: fit-content;
    border-radius: 5px;
    font-size: 10px;
    margin-bottom: 10px;
    margin-left: 10px;

}

.projectInfo{
    position: fixed;
    top:70px;
    left: 0px;
    right: 0px;
    padding: 5px;
    background: var(--darkBlue);
    z-index: 99;
    text-align: left;
    color:white;
    padding: 10px;
    padding-left: 18px;
    padding-right: 18px;
    display: flex;
    justify-content: space-between;
   

}

.progress{
    margin-right: 2rem;
    color:white;
    display: flex;
    align-items: center;
}

.projectInfo p{
    font-size: 12px;
   
}

.progressIcon{
    font-size: 2rem;
    margin-right: 10px;
    
}

.projectInfo h2{
    font-size: 16px;
    color: white;
    font-weight: bold;

}


.addComment{
    display: flex;
    align-items: center;
    
}

.profilePic{
    width:50px;
    height:50px;
    object-fit: cover;
    border-radius: 100px;
}

.addCommentInput{
    width: 100%;
    border: none;
    border-bottom:1px solid lightgray;
    margin-left: 10px;
    padding: 5px 10px;
}

.addCommentInput:focus{
    outline: none;
    border-bottom:2px solid rgb(41, 41, 41);
    transition: 0.2s;
}

.commentBtnArea{
    margin-top: 7px;
    display: flex;
    justify-content: flex-end;
}

.replyArea{
    margin-left: 7%;
}

.projectMaterialWrapper{
    display: flex;
    align-items: center;
}


.congratsImg{
    margin-left:auto;
    margin-right: auto;
    display: block;
    
}

.star{
    width: 40px;
    height:40px;
    background-image: url('../../assets/gray-star.png');
    background-size: cover;
    margin: 10px;
    transition: 0.3s;
    cursor: pointer;
}

.star:hover{
    background-image: url('../../assets/star.png');
    
}

.filledStar{
    background-image: url('../../assets/star.png');
    width: 40px;
    height:40px;
    background-size: cover;
    margin: 10px;
    transition: 0.3s;
    cursor: pointer;
}

.feedbackStars{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    align-items: center;

}

.editIcon{
    font-size: 1rem;
    margin-left: 10px;
    margin-top: 5px;
}

.editIcon:hover{
    color: var(--green);
}