.login{
    display: flex;
    flex-wrap: wrap;
}

.login .bgBlue{
    /* background-color: rgb(0, 86, 114); */
    background-color:  var(--primaryBlue);
    background-image: url("./../../../assets/codelinesbg.png"), url("./../../../assets/codelinesMore.png");
    background-repeat: no-repeat, no-repeat;
    background-position: left top, right bottom;
    background-size: 266px, 335px;
    height: 100vh;
    width: 40%;
}

.login .logoArea{
    margin-top: 25%;
    margin-bottom: 50%;
}

.login .logoArea img{
    width: 5rem;
}

.login .logoArea .logoText{
    font-size: 1.2rem;
    font-weight: bold;
    color: white;
    font-family: 'Montserrat', sans-serif;
}

.login .logoArea .logoDesc{
    text-align: left;
    color: white;
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 2%;
    font-family: 'Josefin Sans', sans-serif;
}

.login .logoArea a{
    color: var(--green);
    font-family: 'Josefin Sans', sans-serif;
    text-decoration: none;
}

.login .logoArea a:hover{
    text-decoration: underline;
    color: white;
}

.login .loginBtn{
    background: var(--green);
    color: white;
    padding: 8px 30px;
    border-radius: 3px;
    align-items: center;
    display: flex;
    width: fit-content;
    border: none;
    justify-content: space-between;
    text-decoration: none;
    font-size: 1rem;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Cabin', sans-serif;
    margin-top: 20px;
}

.login .loginBtn:hover{
    background: var(--darkGreen);
}

.login .loginBtn:disabled{
    cursor: not-allowed;
    background: lightgray;
    color: gray;


}

.login .registeredSuccess{
    padding-right: 6%;
    padding-top: 5%;
}

.login .registeredSuccess img{
    width:50%;
    
}

.login .registeredSuccess h3{
    font-weight: bold;
    font-family: 'Open Sans',sans-serif;
    color: #07CC66;
}

.login .form-area{
    margin-top: 7%;
    width: 50%;
    margin-left: 10%;
    
}

.login .form-area h4{
    font-size: 1.5rem;
    margin-top: 0%;
    font-family: 'Cabin', sans-serif
}

.login .form-area form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
}

.login .form-area form .formGroup{
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    margin-bottom: 10px;
    margin-top: 10px;
}

.login .form-area form .formGroup label{
    font-family: 'Cabin', sans-serif;
    margin-bottom: 8px;
}

.login .form-area form .formInput{
    background: rgb(238, 237, 237);
    border: none;
    padding: 10px 20px;
    font-family: 'Open Sans', sans-serif;
}

.login .form-area form .formInput:hover{
    border:none !important;
}

.login .form-area form .formInput::placeholder{
    font-family: 'Open Sans', sans-serif;
}

.login .form-area form .formInput:focus{
    outline: 2px solid var(--primaryBlue);
    background: white;
}

.login .form-area form .loginImg img{
    width: 8rem;
}

.login .form-area .passwordField{
    display: flex;
    justify-content: space-between;
    width: 110%;
    align-items: center;

}

.login .form-area .passwordField .formInput{
    width: 100%;
}

.login .form-area .passwordField .passwordIcon{
    margin-left: 10px;
    font-size: 1.3rem;
    cursor: pointer;
}
.login .formBottomLink{
    color: rgb(97, 95, 95);
    font-family: 'Open Sans',sans-serif;

}

.login .error{
    color: red;
}

.login .formBottomLink .link{
    font-weight: bold;
    color: rgb(0, 102, 255);
    font-family: 'Roboto',sans-serif;
    
}

@media screen and (max-width: 500px){
    .login{
        display: flex;
        flex-direction: column;
    }

    .login .logoArea{
        margin-top: 2%;
        margin-left: 10%;
    }

    .login .logoArea .logoDesc,.login .logoArea a{
        display: none;
    }

    .login .bgBlue{
        width: 100%;
        height: 30vh;
    }

    .login .form-area {
        width: 100%;
        padding-bottom: 5%;
    }

    .login .formInput{
        width: 100%;
    }

}
