nav {
    position: fixed;
    top: 0;
    width: 100%;
    height: fit-content;
    background: white;
    z-index: 1000;
    padding: 10px 20px;
    display: flex;
    font-family: 'Open Sans', sans-serif;
}

nav .logo{
    display: flex;
    width: fit-content;
    align-items: center;
}

nav .logo img{
    width: 50px;
}

nav .logo .logoTitles{
    display: flex;
    flex-direction: column;
}

nav .logo .logoTitles small{
    margin-left: 0px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: bold;
    color: gray;
}
nav .logo .logoText{
    font-size: 1.1rem;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Montserrat', sans-serif;

}

nav .list {
    list-style-type: none;
    background: white;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: 2%;
    width: 100%;
}
nav .items {
    margin-right: 20px;
    font-size: 1rem;
    cursor: pointer;
    padding: 5px;

}

nav .items .navLink{
    display: flex;
    align-items: center;
}

nav .items .navLink span{
    margin-left: 7px;
}

nav .items a{
    text-decoration: none;
    color: #313131;

}

nav .items a:hover{
    color: black;
    text-decoration: underline;
}

nav .list .items .navIcon{
    font-size: 1.5rem;
}

nav .btn {
    display: none;
    position: absolute;
    right: 10px;
    top: 7px;
    padding: 5px;
    cursor: pointer;
    color: white;
    z-index: 100;
    font-size: 1.7rem;
}

nav .profile-items{
    display: flex;
    width: 100%;
    justify-content: right;

 }

 nav .profile-avatar{
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}



.loginArea{
    display: flex;
    margin-right: 3%;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    right: 0px;
}

.loginArea a{
    text-decoration: none;
    color: rgb(27, 27, 27);
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.loginArea a:hover{
    color: black;
    text-decoration: underline;

}

.loginArea .loginIcon,.loginArea  .signUpIcon{
    margin-right: 6px;
    font-size: 1.3rem;
}




@media screen and (max-width: 500px){
    nav{
        background: linear-gradient(45deg,var(--primaryBlue),#006181);
        display: flex;
        flex-direction: column;
        
    }

    
    
    nav .logo{
        width: 100%;
    }

    nav .logo .logoTitles {
        color: white;
        
    }

    nav .logo .logoTitles small{
        color: white;
    }
    nav .list {
        flex-direction: column;
        position: relative;
        top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        background: linear-gradient(45deg,var(--primaryBlue),#006181);
        height: auto;
    }
    nav .items:nth-child(1){
        margin-top: 50px;
    }
    nav .items {
        width: 100%;
        text-align: center;
        margin-right: 0px;
        padding: 20px 0;
        color: white;
    }

    nav .items a{
        color: white;
    }

    nav .items a:hover{
        color:black;
        text-decoration: underline;
    }
    nav .btn {
        display: block;
        color: white;
        margin-right: 8%;
    }
    nav .loginArea{
        position: static;
        display: flex;
        justify-content: space-around;
        color: white;
    }
}
