.courses{
    padding-top:2%;
    
}

.courses h1{
    font-weight: bold;
    text-align: left;
    padding-bottom: 2%;
}

.filterCategories__wrapper{
    display: flex;
    flex-wrap: wrap;

}
.filterCategoryItem{
    padding: 8px 15px;
    border: 2px solid var(--green);
    color: var(--green);
    margin: 5px 10px;
    border-radius: 100px;
    font-weight: bold;
    min-width: 100px;
    cursor: pointer;
}

.activeFilterCategory, .filterCategoryItem:hover{
    background-color: var(--green);
    color: white;
}



.courses .searchArea{
    background-image:
    linear-gradient(to bottom,  rgba(0, 12, 53, 0.73),rgba(4, 44, 155, 0.52)),
    url('../../assets/search.jpg');
    width: 100%;
    height: 500px;
    border-bottom-right-radius: 50% 200px !important;
    background-size: cover;
    padding: 20px;
    
}

.courses .searchArea .searchTitle{
    text-align: left;
    margin-top: 10%;
    margin-left: 3%;
    margin-bottom: 0px;
    font-size: 3rem;
    color: white;
    font-weight: bold;
    text-shadow: 0px 2px 4px rgb(26, 25, 25);
    font-family: 'Cabin',sans-serif;
}

.courses .searchArea .subText{
    font-size: 1rem;
    color: white;
    text-align: left;
    margin-left: 4%;
    margin-top: 10px;
    margin-bottom: 20px;
   
    
}

.courses .search{
    background-color: white;
    text-align: left;
    display: flex;
    align-items: center;
    width: 50%;
    height: 50px;
    margin-left: 2%;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgb(26, 25, 25);
}

.courses .search .searchIcon{
    font-size: 1.2rem;
    margin-left: 10px;
    margin-right: 5px;
}


.courses .search input{
    height: 100%;
    border:none;
    padding-left:15px;
    width: 80%;
    font-size: 1rem;

}

.courses .search input:hover{
    border: none !important;
}

.courses .search input:focus{
    outline: none;
}

.courses .allCourses{
    height: fit-content;
    padding-left: 1%;
    padding-right: 1%;
    padding-top: 2%;
    padding-bottom: 5%;
}

.courses .search .searchBtn{
    width: 15%;
    height: 80%;
    margin: 6px;
    background-color: var(--green);
    border-radius: 5px;
    border: none;
    font-weight: bold;
    color: white;
}

/* .courses .search .searchBtn:hover{
    background-color: var(--darkGreen);
    cursor: pointer;
} */

.courses .coursesList{
    display: flex;
    flex-wrap: wrap;
    margin-top: 2%;
}

.categorizedCourses{
    display: flex;
    border-top: 1px solid lightgray;
}


.categorizedCoursesList{
width:75%;
margin-left: 2%;
}

.categoriesArea{
    width:20%;
    min-height: 70vh;
}


.courseSearchInput{
    border: 1px solid lightgray;
    width: fit-content;
    height:fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    display: flex;
    align-items: center;
    padding: 6px;
}

.categorizeCourseCardGroup{
    display: flex;
    flex-wrap: wrap;
}

.courseSearchInput input {
    border: none;
    width: 30rem;
    height:100%;
   
}



.courseSearchInput input:focus{
    outline: none;
}
.courseSearchInput button{
    border-radius: 0px;
}

.coursesSearchIcon{
    font-size: 22px;
    margin-right: 7px;
}


.searchInput input:focus{
    outline: none;
}


.emptyBox{
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
}


.categoriesArea .ant-menu-item-selected{
    background-color: rgb(207, 253, 210) !important;
    color: black !important;
    font-weight: bold !important;
   
  }
  
  .categoriesArea .ant-menu-item-selected::after{
    border-right-color:var(--darkGreen) !important;
  }