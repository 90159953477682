.projectDetails{
    min-height:100vh;
}


.projectDetails__header{
    padding:2%;
    background: var(--darkBlue);
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    color: white;
    position: fixed;
    top: 70px;
    left: 0px;
    right: 0px;
    z-index: 99;
}

.projectDetails__header__info{
    width:70%;
}

.projectDetails__header__img{
    width: 25%;
    position: fixed;
    top: 100px;
    right:30px;
    background: white;
    box-shadow: 0 2px 4px rgba(0,0,0,.08),0 4px 12px rgba(0,0,0,.08);
    z-index: 100;

}

.projectDetails__header__img__description{
    padding: 10px;
}

.projectDetails__header__img__description a{
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    border-radius: 0px;
    font-weight: bold;
    width: 50%;
}

.projectDetails__header__img img{
    width: 100%;
}

.projectDetails__header__info h2{
    font-weight: bold;
    color: white;
    font-size: 2rem;
    margin-bottom: 0px;
}

.badge{
    background-color: var(--green);
    padding: 2px 7px;
    font-weight: bold;
    color: white;
    width: fit-content;
    border-radius: 5px;
    font-size: 10px;
    margin-bottom: 10px;

}


.projectVideos{
    text-align: left;
    padding: 2%;
}

.marTop100{
    margin-top:100px;
}

.marTop300{
    margin-top: 300px;
}

.projectVideos h4,.projectVideos h2{
    font-weight: bold;

}

.videoWrapper{
    display: flex;
    width: 70%;
    margin: 1rem;
}

.videoInfo{
    width: 70%;
    margin-left: 20px;

}

.videoImg{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.playIcon{
    font-size: 2rem;
    color: white;
}

.playIcon:hover{
    color: rgb(179, 179, 179);
    cursor: pointer;
}


.reviewsArea{
    margin-top: 2rem;
    text-align: left;
    padding: 2rem;
}

.allReviews{
    display: flex;

}

.singleReview{
    display: flex;
    padding: 15px;
    background-color: rgb(245, 245, 245);
    min-width: 10rem;
    margin: 20px;
}

.reviewImgArea{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.reviewImgArea img{
    width: 40px;
    height: 40px;
    border-radius: 100px;
}

.reviewData{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}
.reviewData q{
    font-size: 13px;
    color: gray;
}

.projectLearning{
    margin-top:300px;
    text-align: left;
    padding: 2%;
}

.projectLearning h2{
    font-weight: bold;
}

.learningPointCard{
    width: 70%;
}


.learningPoint{
    margin: 10px;
    display: flex;
    align-items: center;
}

.learningPointIcon{
    margin-right: 7px;
    color: var(--green);
}


