.landing{
    display:flex;
    height: 100%;
    flex-wrap: wrap;
    width: 100vw;
    margin-left: 0px;
    margin-right: 0px;
    padding:3% 5%;
    padding-right: 0px;
    position: relative;
    top: 50px;
    left: 0px;
    right: 0px;
    margin-bottom: 9px;
}


.landing .girl{
    width: 32rem;
}

.landing .textArea{
    width: 60%;
    text-align: left;
}

.landing .textArea h1{
    font-size: 2.5rem;
    max-width: 70%;
    margin-bottom: 0px;
    font-family: 'Cabin', sans-serif;
    font-weight: bold;
}

.landing .textArea p{
    color: gray;
    margin-top: 10px;
    margin-bottom: 20px;
    width: 50%;
}

.landing .textArea .rightIcon{
    margin-left: 5px;
}



.landing .wave{
    height: 60%;
    width: 100%;
    border-top-left-radius: 20rem;
    /* background-image: linear-gradient(294deg,#006080,#006181 47%,#003648); */
    /* background-color: rgb(0, 86, 114); */
    background-color: var(--primaryBlue);
    background-image: url("./../../assets/codelinesbg.png"), url("./../../assets/codelinesMore.png");
    background-repeat: no-repeat, no-repeat;
    background-position: left top, right bottom;
    background-size: 266px, 335px;
}

.landing .wave img.plane1{
    width: 10%;
    margin-left:15rem;
    margin-top: 10rem;
    animation-name: plane1;
    animation-duration: 4s;
    animation-iteration-count: infinite;
}





@keyframes plane1 {
    from { 
        width: 15%;
        margin-left:15rem;
        margin-top: 10rem;
        transform: rotate(0deg);
        opacity: 1;
    }
    to{
        width: 5%;
        margin-left:50rem;
        margin-top: 0rem; 
        opacity: 0.5;
        transform: rotate(-45deg);
    }
    
}

@media screen and (max-width:500px){
    .landing {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .landing .wave{
        display: none;
    }
    .landing .girl{
        width: 80%;
        margin-top: 5%;
    }

    .landing .textArea{
        width: 100%;
        margin-top: 5%;
        margin-bottom: 15%;
        
    }
    .landing .textArea h1{
        font-size: 1.2rem;
        width: 100%;
    }

    .landing .textArea p{
        width: 70%;
    }
}








