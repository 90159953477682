.editorSection{
    background-color: var(--primaryBlue);
    background-image: url("./../../assets/codelinesbg.png"), url("./../../assets/codelinesMore.png");
    background-repeat: no-repeat, no-repeat;
    background-position: left top, right bottom;
    background-size: 266px, 335px;
    padding: 5% 4%;
    height: fit-content;
    display: flex;
    text-align: left;
}

.editorSection h1{
    font-weight: bold;
    font-family: 'Cabin',sans-serif;
    color: white;
    font-size: 2.2rem;
    margin-top: -1%;
    margin-bottom: 4%;
}



.editorSection .btnArea .outlineBtn{
    font-size: 1rem;
    padding: 3% 10%;

}



.editorSection .editorText{
    margin-left: 5%;
  
}



.editorSection .editorArea img{
    border-radius: 10px;
}

@media screen and (max-width:500px){
    .editorSection{
        display: flex;
        padding-top: 10%;
        padding-left: 5%;
        padding-right: 5%;
        flex-direction: column;
    }

    
}