.dashboardHome{
    text-align: left;
    overflow: auto;
    display: flex;
}

.dashboardHome .statCard{
    background-color: #3bb78f;
    background-image: linear-gradient(315deg, #3bb78f 0%, #0bab64 74%);
    display: flex;
    padding:1% 2%;
    align-items: center;
    width: 30%;
    border-radius: 10px;
    box-shadow: 0px 5px 7px lightgray;
    margin-right: 5%;
   
}

.dashboardHome .statCard .h1{
    font-size: 2rem;
    color: white;
    margin-bottom: 0px;
    font-weight: bold;
}

.dashboardHome .statCard .icon{
    font-size: 4rem;
    color: white;
}

.dashboardHome .statCard small{
    color: white;
    font-size: 1rem;
}

.dashboardHome .statCard .cardLabel{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 15%;
}

.dashboardHome #pink{
    background-color: #7f53ac;
    background-image: linear-gradient(315deg, #7f53ac 0%, #647dee 74%);
}

.dashboardHome #orange{
    background-color: #fc9842;
   background-image: linear-gradient(315deg, #fc9842 0%, #fe5f75 74%);

}

